import React, {lazy, Suspense, useState} from 'react'
import './App.scss'
import {Routes, Route} from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Layout from './Components/Layout'
import Lottie from "lottie-react"
import animationEvent from './assets/img/ToncoinPreloader.json'

const HomePage = lazy(()=>import(/*webpackChunkName: "Home" */'./Pages/Home'));
const EventPage = lazy(()=>import(/*webpackChunkName: "Events" */'./Pages/Events'));
const LeaderboardPage = lazy(()=>import(/*webpackChunkName: "LeaderPage" */'./Pages/LeaderPage'));

function App() {
  const [preloader, setPreloader] = useState<boolean>(true);
  if(preloader){
    setTimeout(()=>{      
      setPreloader(false)
    }, 2700)
  
  }

  return (
    <div className="App">
      <Routes>
            <Route path='/' element={<Layout/>}>
                <Route index element={<Suspense><HomePage/></Suspense>} />
                <Route path="/events" element={<Suspense><EventPage/></Suspense>} />
                <Route path="/leaderboard" element={<Suspense><LeaderboardPage/></Suspense>} />
            </Route>
        </Routes>
        <ToastContainer position="top-center" />
       <div id="preloader" className={preloader ? '' : 'preloader_hidden'}> <Lottie animationData={animationEvent} loop={true} /></div>
        
    </div>
  );
}

export default App;
