import React from 'react';
import { TonConnectUIProvider } from "@tonconnect/ui-react";
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import WebApp from '@twa-dev/sdk'

window.Buffer = window.Buffer || require("buffer").Buffer;

WebApp.ready();
WebApp.expand();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const manifestUrl =
  "https://tonvillage.io/tonconnect-manifest.json";


root.render(
    <TonConnectUIProvider manifestUrl={manifestUrl}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
    </TonConnectUIProvider>
 
);


